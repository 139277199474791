<template>
  <v-container fluid>
    <v-card flat v-if="labelTitle">
      <v-card-title>
        <v-row justify="center">
          <v-col>
            <span class="headline primary--text">
              {{labelTitle}}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>

    <v-row>
      <v-col v-for="resposta in respostas" :key="resposta.id" cols="12" md="12">
        <v-card>
          <video id="video_resposta" width="100%" controls crossorigin="*">
            <source id="video_resposta_source" :src="`${resposta.url}`">
          </video>
          <v-btn icon @click="visualizarResposta(resposta)">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>    

    <v-dialog 
      v-model="dialogShowVideo"
      scrollable
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-toolbar dense dark color="primary" max-height="50">
          <v-btn icon @click.native="closeVisualizarResposta()" dark>
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{ activeFileShowVideo.name }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon :href="activeUrlShowVideo">
            <v-icon>file_download</v-icon>
          </v-btn>
        </v-toolbar>

        <!-- <v-card-title>
          <v-row>
            <v-col>
              <span class="headline primary--text">
                Nome do arquivo: {{activeFileShowVideo.name}} 
              </span>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-btn @click="closeVisualizarResposta" color="primary">Fechar</v-btn>
        </v-card-title> -->

        <v-card-text>
          <video id="video_dialog" controls crossorigin="*" :width="videoDialogWidth" height="100%"> 
            <source id="video_dialog_source" :src="`${activeUrlShowVideo}`">
          </video>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import { getFileStorage } from "@/utils/storageUtils"

export default {
  name: "resposta-imagem-view",
  props: {
    labelTitle: {
      type: String,
      default: 'Adicionar imagens'
    },
    valor: {
      type: Array,
      default: null
    },
    storageName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      respostas: [],
      dialogShowVideo: false,
      activeUrlShowVideo: null,
      activeFileShowVideo: new FileReader(),
      videoDialogWidth: "100%",
      selected: null,
    };
  },
  methods: {
    visualizarResposta(resposta) {
      if (this.$vuetify.breakpoint.smAndUp) {
        this.videoDialogWidth = "60%";
      } else {
        this.videoDialogWidth = "100%";
      }

      let videoRespostaElement = document.getElementById("video_resposta");
      let videoDialogElement = document.getElementById("video_dialog");
      
      this.dialogShowVideo = true;
      this.activeUrlShowVideo = resposta.url;
      this.activeFileShowVideo = resposta.file;
      
      if(videoRespostaElement != null) {
        videoRespostaElement.pause();
      }

      if(videoDialogElement != null) {
        videoDialogElement.load();
      }
    },
    closeVisualizarResposta() {
      this.dialogShowVideo = false;

      let videoDialogElement = document.getElementById("video_dialog");
      if(videoDialogElement != null) {
        videoDialogElement.pause();
      }
    },
    getArquivosEmit() {
      if(this.valor) {
        this.valor.forEach(arquivo => {
          getFileStorage(arquivo, this.storageName)
            .then((response) => {
              let resposta = {file: new File([""], arquivo.nomeOriginal), url: response.data.url}
              if(this.respostas.indexOf(resposta) < 0) {
                this.respostas.push(resposta);
              }
            })
        })
      }
    },
    emitValue(){
      this.$emit('returValue', {
          componentName: this.$options.name,
          valor: this.respostas
      });
    }
  },
  created() {
    this.getArquivosEmit()
  }
};
</script>



