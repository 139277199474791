
<template>
  <div id="main-exbition" v-if="!inLineText">
    <v-card max-width="550">
      <v-card-title style="padding-bottom: 3px !important" v-if="labelTitle">
        <div class="text-h6 font-weight-regular grey--text">
          {{ labelTitle }}
        </div>
      </v-card-title>
      <v-card-text v-if="labelSubTitle">
        {{ labelSubTitle }}
      </v-card-text>
    </v-card>
    <v-chip-group v-if="showSelectionBoxes"
      active-class="blue blue--text text--accent-4"
      v-model="selected"
      multiple
      column
    >
      <v-chip v-for="(item, i) in items" :key="i"
        :value="item"
        class="mr-1"
        outlined
        small
        label
        :ripple="false"
        style="pointer-events: none"
      >
        {{ item.valor }}
      </v-chip>
    </v-chip-group>
    <v-combobox v-else
      v-model="selected"
      :items="items"
      item-text="valor"
      itemid="ordem"
      append-icon=""
      readonly
      multiple
      outlined
      dense
    ></v-combobox>
  </div>

  <v-chip-group v-else-if="showSelectionBoxes"
    active-class="blue blue--text text--accent-4"
    v-model="selected"
    multiple
    column
  >
    <v-chip v-for="(item, i) in items" :key="i"
      :value="item"
      class="mr-1"
      outlined
      small
      label
      :ripple="false"
      style="pointer-events: none"
    >
      {{ item.valor }}
    </v-chip>
  </v-chip-group>
  <v-combobox v-else
    v-model="selected"
    :items="items"
    item-text="valor"
    itemid="ordem"
    append-icon=""
    hide-details
    outlined
    readonly
    multiple
    dense
  >
    <template v-slot:selection="{attrs, item}">
      <v-chip
        v-bind="attrs"
        class="blue blue--text text--accent-4"
        style="pointer-events: none"
        outlined
        small
        label
      >{{ item.valor }}</v-chip>
    </template>
  </v-combobox>
</template>

<script>
import { compareObjects } from '../comum/Utils'

export default {
  name: "RespostaMultiplaView",
  props: {
    labelTitle: {
      type: String,
      default: null,
    },
    labelSubTitle: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    valor: {
      type: Array,
      default: null,
    },
    showSelectionBoxes: {
      type: Boolean,
      default: false,
    },
    inLineText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  methods: {
    changeItems() {
      if(this.valor && this.valor.length > 0) {
        this.selected = this.items.filter(item => this.valor.filter(resposta => compareObjects(resposta, item)).length > 0)
      }
    }
  },
  created() {
    this.changeItems();
  }
};
</script>
<style scoped>
</style>