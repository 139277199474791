<template>
  <v-container fluid>
    <v-card flat v-if="labelTitle">
      <v-card-title>
        <v-row justify="center">
          <v-col>
            <span class="headline primary--text">
              {{labelTitle}}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>

    <v-row>
      <v-col v-for="(resposta, index) in respostas" :key="resposta.id" cols="12" md="12">

        <div @mouseover="visualizarArquivoHoverArray(resposta, index)" @mouseleave="closeDialogShoArquivoHoverArray(resposta, index)">
          <v-card>
            <object v-if="isPdfFile(resposta.file)" :data="`${resposta.url}#view=FitH`" type="application/pdf" width="100%" height="100%">
              <iframe :src="`https://docs.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(resposta.url)}`" frameborder="0" width="100%" height="100%"></iframe>
            </object>
            <v-img  v-if="!isPdfFile(resposta.file)" :src="`${resposta.url}`" width="100%" height="100%" contain></v-img>

            <v-btn icon @click="visualizarArquivo(resposta, index)">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-card>
        </div>

        <v-dialog 
          :key="`dialog_hover_${resposta.id}`"
          content-class="dialogPreviewOver"
          v-show="resposta.hover"
          scrollable
          width="60%"
          hide-overlay
          transition="dialog-bottom-transition"
          v-model="resposta.dialogHover"
          :fullscreen="resposta.dialogFullScreen"
          persistent
        >
          <v-card flat v-if="!isPdfFile(resposta.file)">
            <v-toolbar dense dark color="primary">
              <v-btn v-show="resposta.dialogFullScreen" icon @click.native="closeDialogShoArquivo()" dark >
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-toolbar-title>{{ resposta.file.name }} </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn v-show="resposta.dialogFullScreen" icon :href="resposta.url" >
                <v-icon>file_download</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-img :src="`${resposta.url}`" width="100%" height="100%" contain></v-img>
            </v-card-text>
          </v-card>

          <pdf-forms-viewer 
            v-show="isPdfFile(resposta.file)"
            :titulo="resposta.file.name"
            :link="resposta.url" 
            :display="resposta.dialogHover"
            @update:display="closeDialogShoArquivo"
            bodyCustomStyle="height: 80vh; overflow: hidden"
            :enableHoverBehavior="!resposta.dialogFullScreen"
          ></pdf-forms-viewer>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import PdfFormsViewer from '../comum/PdfFormsViewer.vue';
import { getFileStorage } from "@/utils/storageUtils"

export default {
  name: "resposta-arquivo-view",
  props: {
    labelTitle: {
      type: String,
      default: 'Adicionar arquivos'
    },
    valor: {
      type: Array,
      default: null
    },
    storageName: {
      type: String,
      default: null
    },
    enableHoverBehavior: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fullScreenDialogActive:false,
      retainFocus:false,
      dialogHover:false,//true,
      dialogHoverArray:[],
      hover:false,
      hoverArray:[],
      respostas: [],
      dialogShowArquivo: false,
      activeUrlShowArquivo: null,
      activeFileShowArquivo: new FileReader(),
      selected: null,
    };
  },
  components: {
    PdfFormsViewer
  },
  methods: {   
    visualizarArquivo(resposta, index) {
      this.activeFileShowArquivo = resposta.file;
      this.activeUrlShowArquivo = resposta.url;
      this.dialogShowArquivo = true;
      this.fullScreenDialogActive = true;
      this.respostas[index].dialogFullScreen = true;
      this.respostas[index].dialogHover = true;
      this.respostas[index].hover = true;

    },
    closeDialogShoArquivo() {
      this.dialogShowArquivo = false;
      this.fullScreenDialogActive = false;
      this.respostas.forEach((ele) => {
        ele.hover = false;
        ele.dialogHover = false;
        ele.dialogFullScreen = false;
      });
      this.hover = false;
    },
    closeDialogShoArquivoArray(index) {
      this.dialogShowArquivo = false;
      this.fullScreenDialogActive = false;
      this.hoverArray[index] = false;
    },
    visualizarArquivoHover(resposta) {
      console.log('visualizarArquivoHover called!')
      if (this.enableHoverBehavior){
        if (!this.fullScreenDialogActive){
          this.activeFileShowArquivo = resposta.file;
          this.activeUrlShowArquivo = resposta.url;
          this.hover = true;
          this.dialogHover = true;
        }
      }
    },
    visualizarArquivoHoverArray(resposta, index) {
      console.log('visualizarArquivoHover called!')
      if (this.enableHoverBehavior){
        if (!this.fullScreenDialogActive){
          this.activeFileShowArquivo = resposta.file;
          this.activeUrlShowArquivo = resposta.url;
          this.respostas[index].hover = true;
          this.respostas[index].dialogHover = true;
          //this.hoverArray[index] = true;
          //this.dialogHoverArray[index] = true;
        }
      }
    },
    closeDialogShoArquivoHover() {
      console.log('closeDialogShoArquivoHover called!')
      if (this.enableHoverBehavior){
        if (!this.fullScreenDialogActive){
          this.hover = false;
          this.dialogHover = false;
        }
      }
    },
    closeDialogShoArquivoHoverArray(resposta, index) {
      console.log('closeDialogShoArquivoHover called!')
      console.log(resposta)
      console.log(this.respostas[index])
      if (this.enableHoverBehavior){
        if (!this.fullScreenDialogActive){
          this.respostas[index].hover = false;
          this.respostas[index].dialogHover = false;
          //this.hoverArray[index] = false;
          //this.dialogHoverArray[index] = false;
        }
      }
    },
    getArquivosEmit() {
      if(this.valor) {
        this.valor.forEach(arquivo => {
          getFileStorage(arquivo, this.storageName)
            .then(response => {
              
              let type = arquivo.fileExtension == "pdf" ? "application/pdf" : "";
              let resposta = Object.assign(
                {
                  file: new File(
                    [""], 
                    arquivo.nomeOriginal, {type: type}
                  ), 
                  url: response.data.url,
                  hover: false,
                  dialogHover: false,
                  dialogFullScreen: false
                }
              )

              if(this.respostas.indexOf(resposta) < 0) {
                this.respostas.push(resposta);
              }
            })
        });
      }
    },
    emitValue(){
      
      this.$emit('returValue', {
          componentName: this.$options.name,
          valor: this.respostas
      });
    },
    isPdfFile(file) {
      return file.type==='application/pdf' ? true : false;
    }
  },
  created() {
    this.getArquivosEmit();
  }
};
</script>

<style scoped>
  ::v-deep .dialogPreviewOver {
    align-self: center;
  }
  
</style>

