<template>
  <div id="main-exbition" v-if="!inLineText">
    <v-row no-gutters>
      <h5 class="mt-1">{{ labelTitle }}</h5>

      <v-switch class="pl-3 py-0 ma-0"
        :label="valor ? 'Sim' : 'Não'"
        :hint="labelSubTitle"
        v-model="valor"
        readonly
      ></v-switch>
    </v-row>
  </div>
  <v-switch v-else class="pl-3 py-0 ma-0"
    :label="valor ? 'Sim' : 'Não'"
    :hint="labelSubTitle"
    v-model="valor"
    readonly
  ></v-switch>
</template>

<script>
export default {
  name: "RespostaVerdadeiroFalsoCreateEdit",
  props: {
    labelTitle: {
      type: String,
      default: "",
    },
    labelSubTitle: {
      type: String,
      default: "",
    },
    valor: {
      type: Boolean,
      default: null,
    },
    inLineText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resposta: null,
    };
  },
  methods: {
    emitValue() {
      this.$emit("returValue", this.resposta);
    },
  },
};
</script>