<template>
  <!--v-dialog
    v-model="dialogPdf"
    fullscreen
    transition="dialog-bottom-transition"
    scrollable
  -->
    <v-card>
      <v-toolbar dense dark color="primary" max-height="50">
        <v-btn v-if="!enableHoverBehavior" icon @click.native="close()" dark>
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="!enableHoverBehavior" icon :href="linkDownload">
          <v-icon>file_download</v-icon>
        </v-btn>
      </v-toolbar>

      <v-dialog
        v-model="dialogLoad" 
        hide-overlay
        persistent width="300"
      >
        <v-card color="primary" dark>
          <v-card-text>
            Carregando documento.....
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div ref="iframeContextPdfViewer" :style="bodyCustomStyle" id="iframeContextPdfViewer"></div>
      <div ref="coverspin" :style="bodyCustomStyle" id="cover-spin"></div>
    </v-card>
  <!--/v-dialog-->
</template>

<!-- TEMPLATE PARA UTILIZAÇÃO DO COMPONENTE...
<pdf-forms-viewer
  :titulo="titulo"
  :link="`https://docs.google.com/viewerng/viewer?embedded=true&url=${activeUrlShowArquivo}`" 
  :display="dialogShowArquivo"
  @update:display="closeDialogShoArquivo"
></pdf-forms-viewer> -->

<script>
export default {
  name: "pdf-forms-viewer",
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    titulo: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    bodyCustomStyle:{
      type: String,
      default: "height: 100%; overflow: hidden",
    },
    enableHoverBehavior: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogPdf: false,
      dialogLoad: false,
      loaded: false,
      linkIframe: "",
      linkDownload: "",
    };
  },
  components: {},
  watch: {
    display(value) {
      this.dialogPdf = value;
      this.dialogLoad = value;
      if (value) {
        this.carregarIframe();
      }
    },
  },
  methods: {
    close() {
     // this.dialogLoad = false;
     // this.dialogPdf = false;
     // this.loaded = false;
      this.$emit("update:display", false);
    },
    carregarIframe() {
      var self = this;

      if (self.loaded || !self.dialogPdf) {
        this.dialogLoad = false;
        return;
      }

      console.log("carregarIframe ---- > ", this.loaded, this.link);
      //var iframeContextPdfViewer = this.$refs.iframeContextPdfViewer;//document.getElementById("iframeContextPdfViewer");
      
      while(!this.$refs.iframeContextPdfViewer){
        console.log ('nao carregou componente')
      }//hack
      
      var iframeContextPdfViewer = this.$refs.iframeContextPdfViewer;//document.getElementById("iframeContextPdfViewer");

      if (iframeContextPdfViewer != null) {
        iframeContextPdfViewer.innerText = "";
        if (this.link) {

          this.linkDownload = this.link.substr(this.link.indexOf("https://st"));
          console.log("this.linkDownload " + this.linkDownload);
          
          this.linkIframe = `https://docs.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(this.link)}`;
          console.log("iframe link " + this.linkIframe);
          
          var iframe = document.createElement("iframe");
          iframe.frameBorder = 0;
          iframe.width = "100%";
          iframe.height = "100%";
          iframeContextPdfViewer.appendChild(iframe);
          // iframe.contentWindow.location.reload(true)
          iframe.src = this.linkIframe;
          // <iframe v-if="loaded && resultado" :id="`pdfFrame${this.resultado.id}`" frameborder="0" width="100%" height="100%"></iframe>
          //this.$nextTick(() => {
          iframe.onload = function () {
            self.loaded = true;
            console.log("carregou!!", self.loaded);
            console.log(self.enableHoverBehavior);
            self.$refs.coverspin.remove();
          };
         // });
          
        }
      }
      this.dialogLoad = false;
      setTimeout(() => {this.carregarIframe()}, 4000);
    },
  },
  created() {
    console.log("created----PdfFormsView");
  },
  mounted() {
    console.log("Mounted----PdfFormsView");
    this.dialogPdf = true;
    this.dialogLoad = true;
    this.$refs.coverspin.classList.add("spinner");
    this.carregarIframe();
  },
  beforeDestroy() {
    this.$emit("update:display", false);
  },
};
</script>

<style scoped>

@keyframes spinner {
  to {transform: rotate(360deg);}
}
 
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: rgb(5, 24, 83);
  animation: spinner .6s linear infinite;
}

</style>
