<template>
  <v-container fluid>
    <v-card flat v-if="labelTitle">
      <v-card-title>
        <v-row justify="center">
          <v-col>
            <span class="headline primary--text">
              {{labelTitle}}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>

    <v-row>
      <v-col v-for="resposta in respostas" :key="resposta.id" cols="12" md="12">
        <!-- <v-card> -->
          <audio id="audio_resposta" class="audio_resposta" controls crossorigin="*">
            <source id="audio_resposta_source" class="audio_resposta_source" :src="`${resposta.url}`">
          </audio>

          <!-- <v-btn icon @click="visualizarResposta(resposta)">
            <v-icon>mdi-magnify</v-icon>
          </v-btn> -->
        <!-- </v-card> -->
      </v-col>
    </v-row>    

    <!-- <v-dialog 
      v-model="dialogShowAudio"
      scrollable
      fullscreen
    >
      <v-card flat>
        <v-card-title>
          <v-row>
            <v-col>
              <span class="headline primary--text">
                Nome do arquivo: {{activeFileShowAudio.name}} 
              </span>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-btn @click="closeVisualizarResposta" color="primary">Fechar</v-btn>
        </v-card-title>
        <v-card-text>
          <audio id="audio_dialog" controls crossorigin="*" :width="audioDialogWidth" height="100%"> 
            <source id="audio_dialog_source" :src="`${activeUrlShowAudio}`">
          </audio>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </v-container>
</template>


<script>
import { getFileStorage } from "@/utils/storageUtils"

export default {
  name: "resposta-audio-view",
  props: {
    labelTitle: {
      type: String,
      default: 'Adicionar audios'
    },
    valor: {
      type: Array,
      default: null
    },
    storageName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      respostas: [],
      dialogShowAudio: false,
      activeUrlShowAudio: null,
      activeFileShowAudio: new FileReader(),
      audioDialogWidth: "100%",
      selected: null,
    };
  },
  methods: {
    visualizarResposta(resposta) {
      if (this.$vuetify.breakpoint.smAndUp) {
        this.audioDialogWidth = "60%";
      } else {
        this.audioDialogWidth = "100%";
      }

      let audioRespostaElement = document.getElementById("audio_resposta");
      let audioDialogElement = document.getElementById("audio_dialog");
      
      this.dialogShowAudio = true;
      this.activeUrlShowAudio = resposta.url;
      this.activeFileShowAudio = resposta.file;
      
      if(audioRespostaElement != null) {
        audioRespostaElement.pause();
      }

      if(audioDialogElement != null) {
        audioDialogElement.load();
      }
    },
    closeVisualizarResposta() {
      this.dialogShowAudio = false;

      let audioDialogElement = document.getElementById("audio_dialog");
      if(audioDialogElement != null) {
        audioDialogElement.pause();
      }
    },
    getArquivosEmit() {
      if(this.valor) {
        this.valor.forEach(arquivo => {
          getFileStorage(arquivo, this.storageName)
            .then((response) => {
              let resposta = {file: new File([""], arquivo.nomeOriginal), url: response.data.url}
              if(this.respostas.indexOf(resposta) < 0) {
                this.respostas.push(resposta);
              }
            })
        });
      }
    },
    emitValue(){
      this.$emit('returValue', {
          componentName: this.$options.name,
          valor: this.respostas
      });
    }
  },
  created() {
    this.getArquivosEmit()
  }
};
</script>

<style>
  .audio_resposta {
    width: 100%; 
    height: 100%; 
  }
</style>