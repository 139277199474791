
<template>
  <div id="main-exbition" v-if="!inLineText">
    <v-card max-width="550">
      <v-card-title style="padding-bottom: 3px !important" v-if="labelTitle">
        <div class="text-h6 font-weight-regular grey--text">
          {{ labelTitle }}
        </div>
      </v-card-title>
      <v-card-text v-if="labelSubTitle">
        {{ labelSubTitle }}
      </v-card-text>
    </v-card>
    <div v-html="texto"></div>
  </div>
  <span v-else v-html="texto"></span>
</template>

<script>
export default {
  name: "RespostaEditoCreateEdit",
  props: {
    labelTitle: {
      type: String,
      default: null,
    },
    labelSubTitle: {
      type: String,
      default: null,
    },
    valor: {
      type: String,
      default: null,
    },
    inLineText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      texto: this.valor,
    };
  },
};
</script>