<template>
  <v-container fluid>
    <v-card flat v-if="labelTitle">
      <v-card-title>
        <v-row justify="center">
          <v-col>
            <span class="headline primary--text">
              {{labelTitle}}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>

    <v-row>
      <v-col v-for="resposta in respostas" :key="resposta.id" cols="12" md="4">
        
          <v-img
            :src="`${resposta.url}`"
            @click="visualizarImagem(resposta)"
            contain
            @mouseover="visualizarImagemHover(resposta)" @mouseout="closeDialogVisualizarImagemHover()"
          >
          </v-img>
      
      </v-col>
    </v-row>

    <v-dialog 
      content-class="dialogPreviewOver"
      v-if="hover"
      scrollable
      width="60%"
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="dialogHover"
    >
      <v-card flat >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title>{{ activeFileShowImage.name }} </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-img :src="`${activeUrlShowImage}`" width="100%" height="100%" contain></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog 
      v-model="dialogShowImage"
      scrollable
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-toolbar dense dark color="primary" max-height="50">
          <v-btn icon @click.native="closeDialogvisualizarImagem()" dark>
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{ activeFileShowImage.name }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon :href="activeUrlShowImage">
            <v-icon>file_download</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-img :src="`${activeUrlShowImage}`" width="100%" height="100%" contain></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import { getFileStorage } from "@/utils/storageUtils"

export default {
  name: "resposta-imagem-view",
  props: {
    labelTitle: {
      type: String,
      default: null
    },
    valor: {
      type: Array,
      default: null
    },
    storageName: {
      type: String,
      default: null
    },
    enableHoverBehavior: {
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      fullScreenDialogActive:false,
      retainFocus:false,
      dialogHover:true,
      hover:false,
      respostas: [],
      dialogShowImage: false,
      activeUrlShowImage: null,
      activeFileShowImage: new FileReader(),
    };
  },
  methods: {    
    visualizarImagem(resposta) {
      this.dialogShowImage = true;
      this.activeUrlShowImage = resposta.url;
      this.activeFileShowImage = resposta.file;
      this.fullScreenDialogActive = true;
      
    },
    closeDialogvisualizarImagem() {
      this.dialogShowImage = false;
      this.fullScreenDialogActive = false;
      this.hover = false;
    },
    visualizarImagemHover(resposta) {
      if (this.enableHoverBehavior){
        if (!this.fullScreenDialogActive){
          this.activeUrlShowImage = resposta.url;
          this.activeFileShowImage = resposta.file;
          this.hover = true;
        }
      }
    },
    closeDialogVisualizarImagemHover() {
      if (this.enableHoverBehavior){
        if (!this.fullScreenDialogActive){
          this.hover = false;
        } 
      }
    },
    getArquivosEmit() {
      if(this.valor) {
        this.valor.forEach(arquivo => {
          getFileStorage(arquivo, this.storageName)
            .then((response) => {
              let resposta = Object.assign({file: new File([""], arquivo.nomeOriginal), url: response.data.url})

              if(this.respostas.indexOf(resposta) < 0) {
                this.respostas.push(resposta);
              }
            })
        })
      }
    },
    emitValue(){
      this.$emit('returValue', {
          componentName: this.$options.name,
          valor: this.respostas
      });
    }
  },
  created() {
    this.getArquivosEmit()
  }
};
</script>
<style scoped>
  ::v-deep .dialogPreviewOver {
    align-self: center;
  }
</style>


