<template>
  <v-container fluid class="ma-0 pa-0" v-if="registroFormulario">
    <v-card>  
      <v-navigation-drawer
        :miniVariant-variant.sync="miniVariant"
        :expand-on-hover="expandOnHover"
        v-model="drawer"
        mini-variant-width="0"
        width="300"
        clipped
        right
        app
      >
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <span class="headline primary--text">
                {{ labelTitle }}
              </span>
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon @click="changePanel"> {{ changePanelIcon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>

        <v-list v-if="responseEmpty">
          <v-list-item>
            <v-list-item-content>
              <h4>{{ titleResposeEmpty }}</h4>
              <v-divider></v-divider>
              <p class="pt-6">{{ textResposeEmpty }}</p>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list>
          <v-expansion-panels multiple accordion class="px-1" v-model="panel">
            
            <template v-for="p in perguntasView">
              <v-expansion-panel
                :key="`${p.ordem}-expansion-panel-resposta`" 
                :id="`expansion-panel-resposta-${p.tipoPergunta.code}`" 
                v-if="possuiValorResposta(p.props.valor) && !['CURTA','EDITOR','MULTIPLA_ESCOLHA','UNICA_ESCOLHA','VERDADEIRO_FALSO','TEXTO'].includes(p.tipoPergunta.code)"
              >
                <v-expansion-panel-header>
                  <h4>{{ p.descricao }}</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <component
                    :is="p.tipoPergunta.nomeComponent"
                    :key="`${p.ordem}-expansion-panel-resposta`"
                    v-bind="{...p.props}"
                  ></component>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-col cols="12" v-else :key="p.ordem" class="px-0 py-1 d-flex flex-wrap">
                <strong class="pr-1">{{ p.descricao }}: </strong>
                <component
                  :is="p.tipoPergunta.nomeComponent"
                  :key="`${p.ordem}-expansion-panel-resposta`"
                  v-bind="{...p.props}"
                ></component>
              </v-col>
            </template>
          </v-expansion-panels>
        </v-list>

      </v-navigation-drawer>
      <alerta></alerta>
    </v-card>
  </v-container>
</template>

<script>
import Alerta from '@/components/comum/Alerta.vue'
import { mapActions } from 'vuex'

import RespostaCurtaView from "@/components/forms/RespostaCurtaView.vue";
import RespostaTextoView from "@/components/forms/RespostaTextoView.vue";
import RespostaVerdadeiroFalsoView from "@/components/forms/RespostaVerdadeiroFalsoView.vue";
import RespostaDataView from "@/components/forms/RespostaDataView.vue";
import RespostaUnicaView from "@/components/forms/RespostaUnicaView.vue";
import RespostaMultiplaView from "@/components/forms/RespostaMultiplaView.vue";
import RespostaAudioView from "@/components/forms/RespostaAudioView.vue";
import RespostaArquivoView from "@/components/forms/RespostaArquivoView.vue";
import RespostaImagemView from "@/components/forms/RespostaImagemView.vue";
import RespostaVideoView from "@/components/forms/RespostaVideoView.vue";
import RespostaEditorView from "@/components/forms/RespostaEditorView.vue";

export default {
  name: "mezzow_forms_view",
  props: {
    labelTitle: {
      type: String,
      default: "História Clínica",
    },
    registroFormularioId: {
      type: String,
      default: null,
    },
    changeDrawerFormsView: {
      type: Boolean,
      default: null,
    },
    storageName: {
      type: String,
      default: null,
    },
    enableHoverBehavior: {
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      drawer: null,
      miniVariant: false,
      expandOnHover: false,
      registroFormulario: null,
      perguntasView: null,
      responseEmpty: false,
      titleResposeEmpty: "",
      textResposeEmpty: "",

      quantidadePerguntas: null,
      panel: [],
      changePanelIcon: "mdi-chevron-down",
    };
  },
  components: {
    Alerta,
    RespostaCurtaView,
    RespostaTextoView,
    RespostaVerdadeiroFalsoView,
    RespostaDataView,
    RespostaUnicaView,
    RespostaMultiplaView,
    RespostaAudioView,
    RespostaArquivoView,
    RespostaImagemView,
    RespostaVideoView,
    RespostaEditorView,
  },
  computed: {
  },
  watch: {
    changeDrawerFormsView() {
      this.changeDrawer();
    },
    registroFormulario() {
      this.makeResponseRegistroFormulario();
    },
    quantidadePerguntas() {
      this.changePanel();
    }
  },
  methods: {
    ...mapActions([
      'setAlerta',
    ]),   
    possuiValorResposta(resposta) {
      if(resposta instanceof Array) {
         return (resposta != null && resposta.length > 0) ? true : false;
      }
      return resposta != null ? true : false;
    },
    changeDrawer() {
      if (this.$vuetify.breakpoint.smAndUp) {
        this.drawer = true;
        this.miniVariant = !this.miniVariant;
        this.expandOnHover = !this.expandOnHover;
      } else {
        this.drawer = !this.drawer;
        this.miniVariant = false;
        this.expandOnHover = false;
      }
    },
    changePanel() {
      if(this.panel.length > 0) {
        this.panel = []
        this.changePanelIcon = "mdi-chevron-down"
      } else {
        this.panel = [...Array(this.quantidadePerguntas).keys()].map((k, i) => i);
        this.changePanelIcon = "mdi-chevron-up"
      }
    },
    makeResponseRegistroFormulario() {
      var self = this;
      // Função recursiva para transformação de string json em objeto javascript
      const jsonToObject = str => typeof str === "object" ?  str : jsonToObject(JSON.parse(str))
      
      // Tratamento dos dados e ordenação de perguntas e respostas
      if(this.registroFormulario != null && this.registroFormulario.perguntas.length > 0) {

        this.perguntasView = Object.assign(this.registroFormulario.perguntas
          .filter(p => p.respostas != null && p.respostas.length > 0));

        // Sai da rotina caso não existam respostas a serem exibidas
        if(this.perguntasView.length == 0) {
          this.titleResposeEmpty = `${this.registroFormulario.nome}` 
          this.textResposeEmpty = `Até o momento não foi registrada nenhuma resposta para o formulário!`;
          this.responseEmpty = true;
          return;
        }

        if(!this.storageName) {
          this.setAlerta( {
            tipoAlerta : 'error',
            textAlerta : 'Storage não cadastrada para a unidade! Favor entre em contato com a equipe de suporte!',
          })
        }

        // Atualiza a quantidade de perguntas do formulário
        this.quantidadePerguntas = this.perguntasView.length;

        // Ordenação crescente das perguntas
        this.perguntasView
        .sort((p1, p2) => {
          return p1.ordem - p2.ordem;
        })
        .sort((p1, p2) => {
          return (p1.perguntaValores?.ordem && p2.perguntaValores?.ordem) ? p1.perguntaValores.ordem - p2.perguntaValores.ordem : null;
        })

        // Realiza o parce das respostas em JSON text para object.
        this.perguntasView.forEach(p => {

          if(p.respostas != null && p.respostas.length > 0) {

            // Converte o valor das respostas para objeto
            let respostasObject = p.respostas.map(r => {
              r.valor = jsonToObject(r.valor)
              return r;
            });
            p.respostas = Object.assign(respostasObject);

            // Tratamento para definição do modelo de exibição [Hard Code - Inicial]
            p.showSelectionBoxes = p.modeloExibicao == "CS" ? true : false
          
            // Executa o import do componente via função
            p.tipoPergunta.nomeComponent = this.defineComponentInstance(p.tipoPergunta.nomeComponent.replace('CreateEdit','View'));
                  
            // Tratamento para renderização dos componentes - transformação de respostas       
            switch(p.tipoPergunta.code) {
              case 'IMAGEM': 
                p.props = {labelTitle: null, storageName: this.storageName, valor: p.respostas.map(r => r.valor)}
                break;
              case 'VIDEO':
                p.props = {labelTitle: null, storageName: this.storageName, valor: p.respostas.map(r => r.valor)}
                break;
              case 'ARQUIVO':
                p.props = {labelTitle: null, storageName: this.storageName, valor: p.respostas.map(r => r.valor)}
                break;
              case 'AUDIO':
                p.props = {labelTitle: null, storageName: this.storageName, valor: p.respostas.map(r => r.valor)}
                break;
              case 'CURTA': 
                p.props = {labelTitle: null, valor: p.respostas[0].valor.valor, inLineText: true}
                break;
              case 'TEXTO': 
                p.props = {labelTitle: null, inLineText: true, valor: p.respostas[0].valor.valor}
                break;
              case 'VERDADEIRO_FALSO': 
                p.props = {labelTitle: null, inLineText: true, valor: p.respostas[0].valor.valor}
                break;
              case 'DATA': 
                p.props = {labelTitle: null, valor: p.respostas[0].valor.valor}
                break;
              case 'UNICA_ESCOLHA':
                p.props = {labelTitle: null, items: p.perguntaValores, showSelectionBoxes: p.showSelectionBoxes, inLineText: true, valor: p.respostas}
                break;
              case 'MULTIPLA_ESCOLHA':
                p.props = {labelTitle: null, items: p.perguntaValores, showSelectionBoxes: p.showSelectionBoxes, inLineText: true, valor: p.respostas}
                break;
              case 'EDITOR': 
                p.props = {labelTitle: null, valor: p.respostas[0].valor.valor, inLineText: true}
                break;
              default:
                console.log(p.tipoPergunta.code);
            }
           
            p.props = Object.assign({enableHoverBehavior:self.enableHoverBehavior},p.props);
          }

        });
      }
    },
    loadModel() {
      var self = this
      if (this.registroFormularioId) {
        
        this.$http.get(`/forms/${this.registroFormularioId}`)
        .then(response => {
          self.registroFormulario = response.data
        })
        .catch(() => {
          self.setAlerta( {
            tipoAlerta : 'error',
            textAlerta : 'Erro ao carregar o formulário',
          })
        })
      }
    },
    defineComponentInstance (nomeComponent) {
      return nomeComponent.replace("forms/", "").replace("audio/", "");
    },
  },
  created() {
    this.loadModel();
  },
};
</script>