
<template>
  <div>
    <v-card max-width="550">
      <v-card-title style="padding-bottom: 3px !important" v-if="labelTitle">
        <div class="text-h6 font-weight-regular grey--text">
          {{ labelTitle }}
        </div>
      </v-card-title>
      <v-card-text v-if="labelSubTitle">
        {{ labelSubTitle }}
      </v-card-text>
    </v-card>
    <input v-if="valor"
      v-model="valor"
      placeholder="sua resposta"
      type="date"
      readonly
      ref="respostaDataInputElement"
      id="respostaDataInputElement"
      style="border-style:double"
    />
  </div>
</template>

<script>
export default {
  name: "RespostaDataView",
  props: {
    labelTitle: {
      type: String,
      default: null,
    },
    labelSubTitle: {
      type: String,
      default: null,
    },
    valor: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      data: "",
    };
  },
  watch: {},
  methods: {
    emitValue() {
      this.$emit("returValue", this.data);
    },
  },
};
</script>