<template>
  <v-container fluid>
    <MezzowFormsView v-if="registroFormularioId"
      :registroFormularioId="registroFormularioId"
      :changeDrawerFormsView="changeDrawerFormsView"
      :storageName="getStorageName"
      :enableHoverBehavior="mezzowFormEnableHoverBehavior"
    ></MezzowFormsView>
    <v-card flat>
      <v-form
        lazy-validation
        v-on:submit.prevent="onSubmit"
        enctype="multipart/form-data"
        ref="form"
      >
        <v-card-title>
          <v-row class="mb-1 px-3" justify="end">
            <span class="headline primary--text">{{ titulo }}</span>
            <v-hover v-slot="{ hover }">
              <v-btn v-show="qrCodeAtendimento"
                icon 
                class="ml-2"
                color="primary">
                <v-icon>mdi-qrcode</v-icon>
                <div v-if="hover" class="qrcode_container">
                  <div class="qrcode">
                    <v-img contain :src="qrCodeAtendimento"></v-img>
                  </div>
                </div>
              </v-btn>
            </v-hover>
            <v-spacer></v-spacer>
            <v-tooltip top> 
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined class="mr-1 mb-1" ripple color="primary" v-bind="attrs" v-on="on"
                  v-show="!atendimento.atendimentoAtendidoExpirado"
                  :style="visibility"
                  @click="openTeleConsulta" :href="atendimento.linkTeleConsultaMedico" target="_blank"
                  width="215"
                >
                  <v-icon class="mr-1">window</v-icon> {{ msgAtendimentoAtendido }}
                </v-btn>
                <v-btn outlined class="mr-1 mb-1" ripple color="primary" v-bind="attrs" v-on="on"
                  v-show="atendimento.atendimentoAtendidoExpirado"
                  :style="visibility"
                  width="215"
                >
                  {{ msgAtendimentoAtendido }}
                </v-btn>
              </template>
              <span>{{ msgAtendimentoAtendido }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined class="mr-1 mb-1" ripple color="primary" v-bind="attrs" v-on="on"
                  @click="changeDrawerFormsView = !changeDrawerFormsView"
                  :disabled="registroFormularioId == null"
                  width="179"
                >
                  <span>Dados Clínicos</span>
                </v-btn>
              </template>
              <span>Ocultar/Exibir Dados Clínicos</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined class="mb-1" ripple color="primary" icon="icon" v-bind="attrs" v-on="on"
                  @click="cancelar"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              <span>Voltar</span>   
            </v-tooltip>
          </v-row>
        </v-card-title>
 
        <v-card-text>
          <v-row>
            <v-col md="4" lg="5" cols="12">
              <v-card elevation="1" min-height="205">
                <v-card-text>
                  <v-row dense>
                    <v-col cols="auto"><div class="lista-dados-atendimento">Nome:</div></v-col>
                    <v-col cols="auto"><strong>{{ atendimento.registroPreAtendimento.agendamento.paciente.nome }}</strong></v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto"><div class="lista-dados-atendimento">CPF:</div></v-col>
                    <v-col cols="auto"><strong>{{ atendimento.registroPreAtendimento.agendamento.paciente.cpf }}</strong></v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto"><div class="lista-dados-atendimento">E-mail:</div></v-col>
                    <v-col cols="auto"><strong>{{ atendimento.registroPreAtendimento.agendamento.paciente.email }}</strong></v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto"><div class="lista-dados-atendimento">Tipo Consulta:</div></v-col>
                    <v-col cols="auto"><strong>{{ atendimento.registroPreAtendimento.agendamento.tipoConsulta.nome }}</strong></v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto"><div class="lista-dados-atendimento">Data Consulta:</div></v-col>
                    <v-col cols="auto"><strong>{{ new Date(atendimento.registroPreAtendimento.agendamento.dataConsulta).toLocaleString('pt-BR') }}</strong></v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="auto"><div class="lista-dados-atendimento">Unidade:</div></v-col>
                    <v-col cols="auto"><strong>{{ atendimento.registroPreAtendimento.agendamento.unidade.nome }}</strong></v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="8" lg="7" cols="12">
              <AtendimentoPacienteList :atendimentoId="atendimento.id" :cpfPaciente="atendimento.registroPreAtendimento.agendamento.paciente.cpf"/>
            </v-col>
          </v-row>
           
          <v-row dense>
            <v-col id="top-side-atendimento" cols="12" v-if="modeloFormularios">
              <v-chip-group
                active-class="blue blue--text text--accent-4"
                v-model="modeloFormulario"
                column
              >
                <v-chip v-for="(modeloFormulario, index) in modeloFormularios" :key="index"
                  :value="modeloFormulario"
                  outlined
                  label
                  :ripple="false"
                >
                  {{ modeloFormulario.nome }}
                </v-chip>
              </v-chip-group>
            </v-col>
            <!-- <v-col id="left-side-atendimento" cols="12" md="3">
              <v-row>
                <v-col id="external-report" cols="6" md="12" align-self="center">
                  <v-switch
                    v-model="flgRelatorioAtendimentoExterno"
                    label="Relatório de Atendimento Externo"
                  ></v-switch>
                </v-col>
                <v-col id="medical-report" cols="6" md="12" align-self="center" v-if="!flgRelatorioAtendimentoExterno">
                  <v-card flat>
                    <v-card-title class="pl-0 ml-0">Relatórios Médicos</v-card-title>
                    <v-card-text class="pl-0 ml-0">
                      <v-tooltip bottom close-delay="100">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn-toggle
                            v-model="relatorioMedicoBottom"
                            class="d-inline-block"
                            color="primary"
                            multiple
                            dense
                          >
                            <v-btn 
                              v-for="(selection, i) in categories" 
                              :disabled="loadingRelatorioMedico"
                              :key="`${i}_selection.titulo`" 
                              elevation="1"
                              outlined 
                              rounded
                              x-small
                              v-bind="attrs" 
                              v-on="on" 
                            >
                              <span>{{ selection.titulo }}</span>
                            </v-btn>
                          </v-btn-toggle>
                        </template>
                        <span>Selecionar Relatório</span>
                      </v-tooltip>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col> -->
            <v-col id="atendimento-report" cols="12" md="12" v-if="!flgRelatorioAtendimentoExterno">
              <v-col cols="12" class="mt-0 pa-0">
                <v-card flat v-if="!possuiFormularioAtendimento">
                  <v-card-title soft>Título</v-card-title>
                  <v-card-text>
                    <v-text-field
                        :disabled="!loadAtendimentoRelatorioMedico"
                        :loading="!loadAtendimentoRelatorioMedico"
                        v-model="atendimento.titulo"
                        filled
                        rounded
                    ></v-text-field>

                    <v-row v-if="loadAtendimentoRelatorioMedico">
                      <v-col>
                        <Editor
                          :titleEditor="true"
                          :title="tituloTecnicaRelatorio"
                          :value="atendimento.conteudo"
                          @input="addRelatorioMedicoText"
                        ></Editor>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <FormularioView v-else-if="formularioAtendimento && formularioAtendimento._id"
                  :id="`${formularioAtendimento._id}`"
                  :key="`${formularioAtendimento._id}`"
                  :storageNameParam="getStorageName"
                  :domain="'com.mezzow.domain.Atendimento'"
                  :domainId="atendimento.id"
                  :formularioParam="formularioAtendimento"
                  ref="formView"
                  :gravarFormulario="gravaFormularioAtendimento"
                  @confirmSaveForms="confirmSaveForms($event)"
                ></FormularioView>
              </v-col>
              <v-col cols="12" class="text-center pt-0" v-if="isAtendimentoAssinado">
                <v-chip small outlined color="red" text-color="default">
                  <span v-text="mensagemAtendimentoAssinado"></span>
                </v-chip>
              </v-col>
            </v-col>
          </v-row>

           <v-row> 
            <v-col cols="12" md="4">
              <v-dialog v-model="registroPosConsultaComponentOpts.dialogPosConsulta" >
                <RegistroPosConsultaComponent 
                  :isModal=true
                  :idParameter="atendimento.registroPosConsulta.id"
                  :nomeTipoConsulta="atendimento.registroPreAtendimento.agendamento.tipoConsulta.nome"
                  :inativo="inativo && !isAdminMaster()"
                  v-if="registroPosConsultaComponentOpts.showPosConsultaForm" 
                  @updated="posConsultaUpdated"
                  @saved="posConsultaSaved"
                  @error="posConsultaError"
                  @exit="posConsultaExit"
                />
              </v-dialog>
            </v-col>
          </v-row>

        </v-card-text>
        
        <v-card-actions class="pr-4">
          <v-row justify="end">
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-1 mb-1" 
              width="250px"
              :disabled="!statusAtendido"
              @click="showPosConsulta"
              color="primary"> 
              Orientação Pos Consulta
            </v-btn>
            <v-btn 
              class="mr-1 mb-1"
              width="250px"
              v-if="!flgRelatorioAtendimentoExterno && atendimento.relatorioMedicoId != null"
              @click="visualizarImpressao" 
              download 
              :loading="loadingBtnVisualizarImpressao"
              :disabled="(loadingBtnVisualizarImpressao || !statusAtendido)"
              color="primary"> 
              Visualizar Impressão 
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-btn class="mr-1 mb-1" width="250px" v-show="!inativo" v-if="hasPermissaoFinalizarAtendimento" type="submit" color="primary" :disabled="isStatusCancelado || isAtendimentoAssinado && !isPerfilIdUsuarioLogadoEqualsPerfilIdUsuarioAssinatura"> {{tituloFinalizarAtualizarConsulta}} </v-btn>
            <v-btn class="mr-1 mb-1" width="250px" color="secondary" @click="cancelar"> Voltar </v-btn>
          </v-row>
        </v-card-actions>

        <alerta></alerta>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import Alerta from "@/components/comum/Alerta.vue";
import Editor from "@/components/comum/Editor";
import RegistroPosConsultaComponent from '@/views//atendimento/RegistroPosConsultaCreateEdit.vue'
import { getPerfilId, possuiPermissao, isAdminAuxiliar } from "../../seguranca";
import { mapActions, mapGetters } from "vuex";
import moment from 'moment'
import { bus } from '../../main'

import MezzowFormsView from "@/components/forms/MezzowFormsView.vue";
import FormularioView from "@/components/forms/FormularioView.vue";
import AtendimentoPacienteList from './AtendimentoPacienteList.vue';

export default {
  name: "atendimento-create-edit",
  data() {
    return {
      relatorioMedicoBottom: [],
      mezzowFormEnableHoverBehavior:true,
      inativo: true,
      msgAtendimentoAtendido: 'Abrir Teleconsulta',
      flgRelatorioAtendimentoExterno: false,
      registroPosConsultaComponentOpts:{
        showPosConsultaForm: false,
        dialogPosConsulta: false,
        lastPosConsultaEvent: "",
        lastPosConsultaDados: {},
      },
      loader: null,
      loadAtendimentoRelatorioMedico: false,
      loadingBtnVisualizarImpressao: false,
      loadingTitulo: false,
      visibility: 'visibility: hidden;',
      displayVisibility: 'display: none;',
      icone: 'mdi-clipboard-outline',
      tituloTecnicaRelatorio: 'Relatório de Consulta',
      itemsRelatorioMedico: [],
      loadingRelatorioMedico: false,
      search: '',
      selectedRelatorioMedico: [],
      searche: '',
      selected: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['paciente.nome'],
        sortDesc: [false]
      },
      loading: true,
      headers: [
        {
          text: 'Nome',
          align: 'left',
          sortable: true,
          value: 'registroPreAtendimento.agendamento.paciente.nome'
        },
        {
          text: 'Tipo Consulta',
          align: 'left',
          sortable: true,
          value: 'registroPreAtendimento.agendamento.tipoConsulta.nome'
        },
        {
          text: 'Data Consulta',
          align: 'left',
          sortable: true,
          value: 'registroPreAtendimento.agendamento.dataConsulta'
        },
        {
          text: 'Unidade',
          align: 'left',
          sortable: false,
          value: 'registroPreAtendimento.agendamento.unidade.nome'
        }
      ],
      items:[],
      tiposConsultas: [],
      isDisabledCpf: true,
      isDisabledOutrosDadosPessoais: true,
      dataNascimento: "",
      dataNascimentoMenu: false,
      dataConsulta: "",
      dataConsultaMenu: false,
      tab: null,
      statuss: [{nome: 'AGENDADO', id:'1'},{nome: 'PENDENTE', id:'2'},{nome: 'CONFIRMADO', id:'3'}, {nome: 'EM CANCELADO', id:'4'}] ,
      tabItems: [
        "Dados Pessoais",
        "Dados Profissionais",
        "Perfil",
        "Empresa/Permissões",
      ],
      atendimentoForm: {
        linkGerarPdf: '',
      },
      atendimento: {
        registroPosConsulta: {},
        registroPreAtendimento: {
          agendamento: {
            dataConsultaFormatada: '',
            dataAtendimentoFormatada: '',
            tipoConsulta: {
              nome: ''
            },
            unidade: {
              nome: ''
            },
            paciente: {
              cpf: '',
              dataNascimento: "",
              nome: ''
            },   
          }
        },
        statusAtendimento:{
          id:0
        }
      },
      genders: [
        { id: "M", nome: "Masculino" },
        { id: "F", nome: "Feminino" },
      ],
      modalidades: [],
      empresas: [],
      especialidades: [],
      ufs: [],
      permissoes: [],
      empresasSelecionadas: [],
      unidades: [],
      setores: [],
      grupoEmpresas: [],
      errors: [],
      titulo: "Informações do Atendimento",
      confirm: {
        title: "Atenção",
        contentHtml:
          "Tem certeza que deseja apagar os usuário(s) selecionada(s).",
        ok: "Sim",
        cancel: "Não",
      },
      rules: {
        campoObrigatorio: [
          v => !!v || 'Campo é obrigatório',
        ],
        textoObrigatorio: [
          v => !!v || 'Campo é obrigatório',
          v => v && !!v.trim() || 'Valor não pode ser branco',
        ],
        emailObrigatorio: [
          v => !!v || 'E-mail obrigatório',
          v => /.+@.+\..+/.test(v) || 'E-mail inválido',
        ],    
        selecaoObrigatoria: [
          (v) => v.length > 0 || "Seleção Obrigatória",
        ],
      },
      dialog: false,
      dialogQrCode: false,
      snackbar: false,
      tipoAlerta: "",
      timeoutAlerta: 6000,
      textAlerta: "",
      perfilFile: null,
      urlPerfilFile: "",
      alteraPerfilFile: false,
      assinaturaFile: null,
      urlAssinaturaFile: "",
      alteraAssinaturaFile: false,
      registroFormularioId: null,
      formulario: {},
      formularios: [],
      changeDrawerFormsView: true,
      formularioAtendimento: {},
      possuiFormularioAtendimento: true,
      gravaFormularioAtendimento: false,
      savedAtendimentoForms: false,
      modeloFormularios: [],
      modeloFormulario: null,
    };
  },
  components: { Alerta, Editor, RegistroPosConsultaComponent,
    MezzowFormsView,
    FormularioView,
    AtendimentoPacienteList
  },
  computed: {
    ...mapGetters(["ultimaTela"]),
    qrCodeAtendimento () {
      if (this.atendimento.id) {
        return `${process.env.VUE_APP_MEZZOW_GATEWAY_URL}qrcode/gerarQR?texto=${process.env.VUE_APP_MEZZOW_WEB_CLIENT_URL}atendimento/edit/${this.atendimento.id}&altura=256&largura=256`
      }
      return ""
    },
    hasPermissaoFinalizarAtendimento(){
      return possuiPermissao(['ROLE_MEDICO_RADIOLOGISTA','ROLE_MEDICO_ATENDIMENTO'])
    },
    allSelected () {
      return this.itemsRelatorioMedico.length > 1 && this.selectedRelatorioMedico.length === this.itemsRelatorioMedico.length
    },
    statusAtendido() {
        if(this.atendimento.statusAtendimento) {
          return  this.atendimento.statusAtendimento.descricao == 'ATENDIDO' ? true : false
        }
        return false;
    },
    tituloFinalizarAtualizarConsulta() {
      if(this.atendimento.statusAtendimento) {
        return this.atendimento.statusAtendimento.id == 2 ? 'Atualizar Consulta' : 'Finalizar Consulta';
      }
      return 'Finalizar Consulta';
    },
    categories () {
      const search = this.search.toLowerCase()
      
      if (!search) return this.itemsRelatorioMedico

      return this.itemsRelatorioMedico.filter(item => {
        const text = item.titulo.toLowerCase()

        return text.indexOf(search) > -1
      });
    },
    selections () {
      const selections = []
      
      if (this.selectedRelatorioMedico.length == 0){
        return [];
      } 
      var ultimoAdicionado = this.selectedRelatorioMedico[this.selectedRelatorioMedico.length - 1];
      
      if (ultimoAdicionado){ 
        selections.splice(0,this.selectedRelatorioMedico.length); 
        selections.push(ultimoAdicionado);
      }
      
      return selections
    },
    isPerfilIdUsuarioLogadoEqualsPerfilIdUsuarioAssinatura() {
      if(this.isAtendimentoAssinado) {
        return getPerfilId() === this.atendimento.usuarioAssinatura.id ? true : false;
      }
      return false;
    },
    isAtendimentoAssinado() {
      if(this.atendimento.dataAssinatura && this.atendimento.usuarioAssinatura) {
        return true;
      } 
      return false;
    },
    isStatusCancelado() {
      return this.atendimento.statusAtendimento.id == "3";
    },
    mensagemAtendimentoAssinado() {
      let dataAssinatura = moment(this.atendimento.dataAssinatura, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
      let mensagem = "Assinado por Dr(a) " + this.atendimento.usuarioAssinatura.nomeExibicao + " em " + dataAssinatura;

      return mensagem;
    },
    getStorageName() {
      return this.atendimento.registroPreAtendimento.agendamento.unidade.storage;
    }
  },
  watch: {
    "relatorioMedicoBottom"(newValue, oldValue){
      var self = this;
      this.$nextTick(() => {
          self.changeRelatorioMedicoBottom(newValue, oldValue)
      });
        
    },
    "atendimento.paciente.dataNascimento"() {
      this.dataNascimento = moment(this.atendimento.paciente.dataNascimento, "YYYY-MM-DD")
        .format("DD/MM/YYYY");                                    
    },
    selectedRelatorioMedico () {
      this.search = ''
    },
    loader(){
      const l = this.loader
      this[l] = !this[l]

      this.loader = null
    },
    formularios() {
      // Busca o formulários a ser exibido
      this.selecionaFormularioAgendamento();
    },
    modeloFormulario () {
      if (this.modeloFormulario && this.modeloFormulario._id) {
        this.formularioAtendimento = this.modeloFormulario
        this.flgRelatorioAtendimentoExterno = false
        this.possuiFormularioAtendimento = true
        return
      } else if (this.modeloFormulario && this.modeloFormulario.code === "relatorio_externo") {
        this.flgRelatorioAtendimentoExterno = true
        this.formularioAtendimento = {}
        return
      }
      this.possuiFormularioAtendimento = false
      this.flgRelatorioAtendimentoExterno = false
    }
  },
  methods: {
    ...mapActions(["setAgendamentoQuery", "setAlerta"]),
    changeRelatorioMedicoBottom(value, oldValue) {
      if (value.length == 1){ // marcando 1
        this.selectRelatorioMedico(Object.assign({},this.categories[this.relatorioMedicoBottom[0]]))
      }else{
        var shifitado = null;
        if (value.length > 1){ //tentativa de marcar mais de 1
          shifitado = this.relatorioMedicoBottom.shift()
          this.selectRelatorioMedico(Object.assign({},this.categories[this.relatorioMedicoBottom[0]]))
        }else{ //desmarcando todos
          shifitado = oldValue;
        }
        this.limparRelatorioMedico(shifitado)
      }
    },
    isAdminAuxiliar(){
      return isAdminAuxiliar();
    },
    posConsultaUpdated(){
      this.hidePosConsulta()
    },
    posConsultaSaved(objPosConsultaSaved){
      this.atendimento.registroPosConsulta = objPosConsultaSaved.data;
      this.updateAtendimentoPut();
      this.hidePosConsulta()
    },
    posConsultaError(){
      this.hidePosConsulta()
    },
    posConsultaExit(){
      this.hidePosConsulta()
    },
    showPosConsulta(){
      this.registroPosConsultaComponentOpts.showPosConsultaForm = true;
      this.registroPosConsultaComponentOpts.dialogPosConsulta = true;

      // Converte ID para String para passar o parámetro para o componente RegistroPosConsultaComponent.
      if(this.atendimento.registroPosConsulta.id) {
        this.atendimento.registroPosConsulta.id = this.atendimento.registroPosConsulta.id.toString();
      }
    },
    hidePosConsulta(){
      this.registroPosConsultaComponentOpts.showPosConsultaForm = false;
      this.registroPosConsultaComponentOpts.dialogPosConsulta = false;
    },
    visualizarImpressao(){
      this.loader = 'loadingBtnVisualizarImpressao';
      this.$http.get(`/atendimento/download/${this.$route.params.id}`, {responseType: 'blob'})
          .then((response) => {
            this.loadingBtnVisualizarImpressao = false;
            let blob = new Blob([response.data], { type: 'application/pdf' }),
            url = this.$window.URL.createObjectURL(blob)
            this.$window.open(url)
          });
    },
    openTeleConsulta(){
     // window.location.href = this.atendimento.linkMedicoTeleConsulta;
    },

    selectRelatorioMedico(item){
      this.selectedRelatorioMedico.push(item)
      this.atendimento.relatorioMedicoId = item.id;
      this.atendimento.titulo = item.titulo;
      this.atendimento.conteudo = item.conteudo;
      this.atendimento = Object.assign({}, this.atendimento);

    },
    limparRelatorioMedico(i){
      
      this.selectedRelatorioMedico.splice(i, 1)
      
      // LIMPA OS DADOS DO RELATÓRIO MÉDICO SELECIONADO E DO RELATÓRIO MÉDICO DO ATENDIMENTO
      this.selectedRelatorioMedico = [];
      this.atendimento.relatorioMedicoId = null;

      this.atendimento.titulo = '';
      this.atendimento.conteudo = '';

    },
    addRelatorioMedicoText(_textoCorpo){
      this.atendimento.conteudo = _textoCorpo;
    },
    loadModel() {
      
      var self = this;
      bus.$emit('clickSearchFilter', '');

      if (this.$route.params.id) {
        var url = `/atendimento/${this.$route.params.id}`;
        this.$http
          .get(`${url}`)
          .then((response) => {
              self.atendimento = Object.assign({}, response.data);

              // Atualiza o tipo de atendimento [Relatório Externo]
              self.flgRelatorioAtendimentoExterno = self.atendimento.flgRelatorioAtendimentoExterno
              
              if(!self.atendimento.registroPosConsulta) {
                self.atendimento.registroPosConsulta = {};
              }

              self.atendimentoForm.linkGerarPdf = `${process.env.VUE_APP_MEZZOW_GATEWAY_URL}atendimento/download/${this.$route.params.id}`;

              this.displayVisibility = "display: block;";

              if (self.atendimento.linkTeleConsultaMedico != null){
                self.visibility = "visibility: visible;"
              }else{
                self.visibility = "visibility: hidden;"
              }
            
              self.items = Object.assign([{}], [response.data])
              self.buscarRelatoriosMedicosPorTipoConsulta()

              if (self.atendimento.atendimentoAtendidoExpirado){
                if (self.atendimento.statusAtendimento.id == 2){ //atendido
                  self.msgAtendimentoAtendido = 'Consulta Finalizada';
                }else{
                  self.msgAtendimentoAtendido = 'Expirado';
                }
              }

              // Busca os formulários existentes para o agendamento
              this.buscarFormularioByAgendamento();

              // Busca formularios existentes para o atendimento - Relatóio Médico
              this.buscarFormularioByAtendimento()

              //TODO: Removida validação de empresa ativa, uma vez que a unidade está ativa. VERIFICAR MAIS TARDE
              self.inativo = 
                self.atendimento.registroPreAtendimento.agendamento.unidade.situacao === false || 
                // self.atendimento.registroPreAtendimento.agendamento.unidade.empresa.situacao === false ||
                this.isAdminAuxiliar();

          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao carregar Atendimento",
            });
          });
      }
    },
    preencherPacienteFrm(val) {

      if (val.target.value.length == 14){
        
        let url = `/paciente?cpf=${val.target.value}`


        this.$http
          .get(url)
          .then( response => {
            if (response.data.totalSize > 0){
              this.atendimento.paciente = Object.assign({}, response.data.content[0]);
            }
            
           })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Paciente",
          });
        });
      }
     
      this.isDisabledOutrosDadosPessoais = false;

    },
    disableCpfField() {
      this.isDisabledCpf = false;
    },
    
    async onSubmit() {
      
      var self = this;

      this.atendimento.flgRelatorioAtendimentoExterno = this.flgRelatorioAtendimentoExterno;

      if(!this.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: 'error',
          textAlerta: 'Favor preencher todos os campos'
        })
      } 
      // else if(!this.flgRelatorioAtendimentoExterno && !this.atendimento.relatorioMedicoId) {
      //   self.setAlerta({
      //     tipoAlerta: 'error',
      //     textAlerta: 'Favor selecionar um relatório médico para o atendimento!'
      //   })
      // } 
      else if (!this.flgRelatorioAtendimentoExterno && this.possuiFormularioAtendimento && !this.$refs.formView.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: 'error',
          textAlerta: 'Favor preencher todos os campos do formulário'
        })
      } else {    
        if (this.atendimento.id) {
          this.updateAtendimentoPut();
        }  
      }
    },

    async updateAtendimentoPut(){
      var self = this;

      let atendimentoRequest = Object.assign({}, self.atendimento) ;

      atendimentoRequest.statusAtendimento = Object.assign({}, {nome: 'ATENDIDO', id:'2'}) ;
        
      if (atendimentoRequest.registroPreAtendimento.id) {
        atendimentoRequest.registroPreAtendimento = Object.assign({}, {id : atendimentoRequest.registroPreAtendimento.id}) ;
      }

      if (atendimentoRequest.registroPosConsulta.id){
        atendimentoRequest.registroPosConsulta = Object.assign({}, {id : atendimentoRequest.registroPosConsulta.id}) ;
      } else {
        delete atendimentoRequest.registroPosConsulta;
      }

      // Caso tenha formulario de atendimento, o atendimento não é gravado imediatamente. Somente após a confirmacao do formulario ocorre a gravação
      if (!this.flgRelatorioAtendimentoExterno && this.possuiFormularioAtendimento && !this.savedAtendimentoForms) {
        this.gravaFormularioAtendimento = true
      } else {
        this.$http
          .put("/atendimento", atendimentoRequest)
          .then(() => {
            self.setAgendamentoQuery({ query: atendimentoRequest.titulo });
            self.cancelar();
            self.setAlerta({
              tipoAlerta: "success",
              textAlerta: "Atendimento realizado com sucesso",
            });
          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao atualizar o Agendamento",
            });
          });
      }
    },
    confirmSaveForms (value) {
      // Controla o fluxo para informar que o formulário já está salvo e executa a atulizacao.
      this.savedAtendimentoForms = value;
      if (value) this.updateAtendimentoPut()
    },
    buscarModalidades() {
      var self = this;
      this.$http
        .get(`/modalidade?max=50`)
        .then((response) => {
          this.modalidades = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar modalidades",
          });
        });
    },
    buscarEmpresas() {
      var self = this;
      this.$http
        .get(`/empresa?max=500`)
        .then((response) => {
          this.empresas = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar empresas",
          });
        });
    },
    buscarUnidades() {
      var self = this;
      this.$http
        .get(`/unidade?max=-1`)
        .then((response) => {
          self.unidades = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar unidades",
          });
        });
    },
    buscarTiposConsultas() {
      var self = this;
      this.$http
        .get(`/tipoConsulta?max=-1`)
        .then((response) => {
          self.tiposConsultas = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao tipos consultas",
          });
        });
    },
    buscarGrupoEmpresas() {
      var self = this;
      this.$http
        .get(`/grupoEmpresa?max=500&situacao=true`)
        .then((response) => {
          this.grupoEmpresas = response.data.content;
        })
        .catch(() => {  
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Grupos de Empresas",
          });
        });
    },
    buscarSetores() {
      var self = this;
      this.$http
        .get(`/setor?max=500`)
        .then((response) => {
          this.setores = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar setores",
          });
        });
    },
    buscarPermissoes() {
      var self = this;
      this.$http
        .get(`/permissao?max=500`)
        .then((response) => {
          this.permissoes = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar permissão",
          });
        });
    },
    buscarUfs() {
      var self = this;
      this.$http
        .get(`/estado?max=500`)
        .then((response) => {
          this.ufs = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Ufs",
          });
        });
    },
    buscarEspecialidades() {
      var self = this;
      this.$http
        .get(`/especialidade?max=500`)
        .then((response) => {
          this.especialidades = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Especialidades",
          });
        });
    },
    async getTipoFormularioIdByNome(nomeTipoFormulario) {
      const self = this
      if (nomeTipoFormulario) {
        let tiposFormularios = await self.$http.get(`/tipoForms?nome=${nomeTipoFormulario}&situacao=true`).then(response => response.data.content)
       
        if (tiposFormularios && tiposFormularios.length > 0) {
          return tiposFormularios[0]._id
        }
      }
    },
    async buscarFormularioByAgendamento() {
      var self = this;
      
      let domain = "com.mezzow.domain.Agendamento"
      let domainId = self.atendimento.registroPreAtendimento.agendamento.id
      let nomeTipoFormulario = "História Clínica"
      let tipoFormularioId = await this.getTipoFormularioIdByNome(nomeTipoFormulario)

      let url = ""
      if (tipoFormularioId) {
        url = `/forms?tipoFormularioId=${tipoFormularioId}&domain=${domain}&domainId=${domainId}`
      } else {
        url = `/forms?nomeTipoFormulario=${nomeTipoFormulario}&domain=${domain}&domainId=${domainId}`
      }

      this.$http.get(url)
        .then((response) => {
          this.formularios = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar formulário de história clínica!",
          });
        });
    },
    selecionaFormularioAgendamento() {

      if(this.formularios != null && this.formularios.length > 0) {

        // let formsFilter = this.formularios.filter(form => form.tipoFormulario.nome == "Historia Clinica");
        let formsFilter = this.formularios.filter(form => form.situacao);

        if(formsFilter != null && formsFilter.length > 0) {
          this.formulario = Object.assign(formsFilter[0]);
          this.registroFormularioId = this.formulario._id;
        }
      }
    },
    async buscarFormularioByAtendimento() {
      var self = this;
      
      let nomeTipoFormulario = "Relatório Médico"
      let domain = "com.mezzow.domain.Atendimento"
      let domainId = self.atendimento.id
      let formulariosAtendimento = []

      // Busca o formulário do atendimento caso exista
      formulariosAtendimento = await self.$http.get(`/forms?nomeTipoFormulario=${nomeTipoFormulario}&domain=${domain}&domainId=${domainId}&situacao=true&order=desc`).then(response => response.data.content)
      if (formulariosAtendimento == null || formulariosAtendimento.length == 0) {
        // Busca o formulário padrão caso não exista um para o atendimento

        let url = ""
        let tipoFormularioId = await this.getTipoFormularioIdByNome(nomeTipoFormulario)
        
        if (tipoFormularioId) {
          url = `/modeloForms/findByTipoFormularioIdPermitidos/${tipoFormularioId}`
          let param = `?`
          if (self.atendimento.registroPreAtendimento.agendamento.unidade && self.atendimento.registroPreAtendimento.agendamento.unidade.id) {
            url += `${param}unidadeId=${self.atendimento.registroPreAtendimento.agendamento.unidade.id}`
            param = `&`
          }
        } else {
          url = `/modeloForms?nomeTipoFormulario=${nomeTipoFormulario}&situacao=true`
        }

        let modelosPermitidos = await self.$http.get(url)
        // Atribui os modelos de formulários permitidos para a lista de exibição
        if (modelosPermitidos && modelosPermitidos.data) {
          modelosPermitidos = modelosPermitidos.data.content || modelosPermitidos.data
          this.modeloFormularios = [{code: "relatorio_externo", nome: "Relatório Externo"}, ...modelosPermitidos]
        }
      }
      
      // Informa ao sistema que não existe formulário para preenchimento
      if(!formulariosAtendimento || formulariosAtendimento.length == 0) {
        self.possuiFormularioAtendimento = false
        return
      }

      // Seta o primeiro formulário encontrado no retorno da api
      self.formularioAtendimento = formulariosAtendimento[0]
    },
    onPerfilFileChange() {
      if(this.perfilFile) {
        this.alteraPerfilFile = true;

        var reader = new FileReader();
        reader.readAsDataURL(this.perfilFile);
        reader.onload = () => {
          this.urlPerfilFile = reader.result;
        };
      }
      else {
        this.alteraPerfilFile = false;
      }
    },
    onAssinaturaFileChange() {
      if(this.assinaturaFile) {
        this.alteraAssinaturaFile = true;

        var reader = new FileReader();
        reader.readAsDataURL(this.assinaturaFile);
        reader.onload = () => {
          this.urlAssinaturaFile = reader.result;
        };
      }
      else {
        this.alteraAssinaturaFile = false;
      }
    },
    getFileStorage(fileObj) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          storage: "hc-teste",
        },
      };
      
      const body = JSON.stringify({
        storageFileName: fileObj.nomeArquivo,
        timeUnit: "MINUTES",
        time: 10
      });

      return this.$storage.post(
        "/cadastro/upload/v1/createTemporaryLink",
        body,
        config,
      );
    },
    clickFile() {},
    async uploadPerfilFile() {

      const formData = new FormData();
      formData.append("file", this.perfilFile);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          codEmpresa: "01",
          codLocal: "001",
          descricao: "Arquivo de imagem do perfil do usuário",
        },
      };

      return this.$storage.post(
        "/cadastro/upload/v1/sync",
        formData,
        config
      );
    },
    async uploadAssinaturaFile() {

      const formData = new FormData();
      formData.append(
        "file",
        this.assinaturaFile
      );

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          codEmpresa: "01",
          codLocal: "001",
          descricao: "Arquivo de assinatura do usuário",
        },
      };

      return this.$storage.post(
        "/cadastro/upload/v1/sync",
        formData,
        config
      );
    },
    cancelar() {
      if (this.ultimaTela) {
        this.$router.push({ path: this.ultimaTela });
      } else {
        this.$router.go(-1);
      }
    },
    buscarRelatoriosMedicosPorTipoConsulta() {
      var self = this;
      
     if (self.atendimento.registroPreAtendimento.agendamento.tipoConsulta && self.atendimento.registroPreAtendimento.agendamento.tipoConsulta.id){
      this.$http.get(`/relatorioMedico/byTipoConsultaIdAndSituacao/${this.atendimento.registroPreAtendimento.agendamento.tipoConsulta.id},${true}`)
      .then((response) => {
        self.itemsRelatorioMedico = []
        if (response.data.length > 0){
          
          self.itemsRelatorioMedico = Object.assign([{}], response.data);  
          
          if (response.data){

            this.loadAtendimentoRelatorioMedico = true;
            var posicaoRelatorioMedicoSelecionado = null;
            var relatorioMedicoSelecionado = response.data.filter(function (rm, index) {
                if (rm.id === self.atendimento.relatorioMedicoId){
                  posicaoRelatorioMedicoSelecionado = index;
                  return true
                }else{
                  return false;
                }                
            });
            
            if (relatorioMedicoSelecionado){
              self.selectedRelatorioMedico.push(relatorioMedicoSelecionado[0]); 
              self.relatorioMedicoBottom.push(posicaoRelatorioMedicoSelecionado);
            }
            
            

          }
        } else {
          this.loadAtendimentoRelatorioMedico = true;
          self.setAlerta({
            tipoAlerta: "warning",
            textAlerta: "OBS: Esta unidade não possui Relatórios Médicos disponíveis!",
          });
        }
      })
      .catch((e) => {
        Promise.reject(self.setAlerta({
          tipoAlerta : 'error',
          textAlerta : !e.response ?  "Connection Refused!" : e.response.data.message
        }));         
      })
     }
    },
    getQrCode(texto, largura, altura) {
      const self = this;

      let url = "/qrcode/gerarQR"
      if (texto) url += `?texto=${texto}`
      if (largura) url += `&largura=${largura}`
      if (altura) url += `&largura=${altura}`

      self.$http
        .get(url, { responseType:"blob" })
        .then((response) => {

          var reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = () => {
            self.qrCodeAtendimento = reader.result
          };

        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao gerar qrCode",
          });
        });
    },
  },
  created() {
    this.loadModel();
  },
};
</script>
<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
.lista-dados-atendimento {
  width: 96px;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.qrcode_container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6;
  outline: none;
}

.qrcode {
  border-radius: 4px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  width: 100%;
  max-width: 500px;
  z-index: inherit;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}
</style>